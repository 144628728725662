import React from "react";
import "./About.scss";

function AboutScreen() {
  return (
    <div className="About">
      <div className="About__container"></div>
      <span>
        KARISSA WALKER is a model, stylist, and image consultant based in Los
        Angeles. Who is soon to be transitioning into design with a release of
        her first womenswear collection.
      </span>
    </div>
  );
}

export default AboutScreen;
