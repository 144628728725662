import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Header } from "./components";
import {
  AboutScreen,
  ContactScreen,
  StylingScreen,
  ShopScreen,
} from "./screens";
import logo from "./FullSizeLogo.png";
import "./App.scss";

function Main() {
  return (
    <div className="App__header">
      <div className="App__header__logo">
        <img src={logo} alt="logo" />
      </div>
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Switch>
          <Route path="/styling">
            <StylingScreen />
          </Route>
          <Route path="/shop">
            <ShopScreen />
          </Route>
          <Route path="/contact">
            <ContactScreen />
          </Route>
          <Route path="/about">
            <AboutScreen />
          </Route>
          <Route exact path="/">
            <Main />
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
