import React from "react";
import "./ImageDescription.scss";

function ImageDescription({
  info: { styling, photography, talent, featuring, credits },
}) {
  function flattenConcat(maybeList) {
    if (typeof maybeList === "string") {
      return maybeList;
    }
    return maybeList.join(", ");
  }
  return (
    <div className="ImageDescription">
      <div className="ImageDescription__styling">
        <h3>Styling</h3>
        <span className="ImageDescription__styling__text">
          {flattenConcat(styling)}
        </span>
      </div>
      <div className="ImageDescription__photography">
        <h3>Photography</h3>
        <span className="ImageDescription__photography__text">
          {flattenConcat(photography)}
        </span>
      </div>
      <div className="ImageDescription__talent">
        <h3>Talent</h3>
        <span className="ImageDescription__talent__text">
          {flattenConcat(talent)}
        </span>
      </div>
      <div className="ImageDescription__featuring">
        <h3>Featuring</h3>
        <span className="ImageDescription__featuring__text">
          {flattenConcat(featuring)}
        </span>
      </div>
      <div className="ImageDescription__credits">
        <h3>Special Credits</h3>
        <span className="ImageDescription__credits__text">
          {flattenConcat(credits)}
        </span>
      </div>
    </div>
  );
}

export default ImageDescription;
