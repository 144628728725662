import React from "react";
import { Link } from "react-router-dom";
import logo from "../../FullSizeLogo.png";
import "./Header.scss";

function Header() {
  return (
    <div className="Header">
      <div className="Header__content-container">
        <Link to="/">
          <img
            className="Header__content-container__logo"
            alt="logo"
            src={logo}
          />
        </Link>
        <ul className="Header__content-container__menu">
          <Link to="/styling">styling</Link>
          <Link to="/shop">shop</Link>
          <Link to="/contact">contact</Link>
          <Link to="/about">About</Link>
        </ul>
      </div>
    </div>
  );
}

export default Header;
