import React, { useLayoutEffect } from "react";
import { Link, Route, Switch } from "react-router-dom";
import { ImageTile, ImageDescription } from "../../components";
import Artists from "./artists.json";
import "./Styling.scss";

const artists = Object.keys(Artists);

function Menu() {
  useLayoutEffect(() => {
    window.history.replaceState(null, null, "/styling");
  });
  return (
    <Route path="/styling">
      {artists.map((artist, key) => (
        <Link
          className="Styling__artist-link"
          to={`/styling/${Artists[artist].slug}`}
          key={key}
        >
          <h1>{artist}</h1>
        </Link>
      ))}
    </Route>
  );
}

function ShowCase({ artist }) {
  return (
    <>
      {Artists[artist].images.map((image, key) => (
        <ImageTile key={key} {...image} />
      ))}
      <ImageDescription info={Artists[artist].info} />
    </>
  );
}

function StylingScreen() {
  return (
    <div className="Styling">
      <Switch>
        {artists.map((artist, key) => (
          <Route key={key} path={`/styling/${Artists[artist].slug}`}>
            <ShowCase artist={artist} />
          </Route>
        ))}
        <Route path="*">
          <Menu />
        </Route>
      </Switch>
    </div>
  );
}

export default StylingScreen;
