import React from "react";
import "./ImageTile.scss";

function ImageTile({ path, position, fullWidth }) {
  return (
    <img
      className={`ImageTile${
        fullWidth ? " ImageTile__full-width" : ""
      } ImageTile__${position}`}
      alt={path}
      src={path}
    />
  );
}

export default ImageTile;
