import React from "react";
import "./Contact.scss";

function ContactScreen() {
  return (
    <a className="Contact" href="mailto:contact@karissawalker.com">
      contact@karissawalker.com
    </a>
  );
}

export default ContactScreen;
